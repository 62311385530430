/** @format */
import React from "react";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import Slider from "react-slick";
import { useDispatch } from "react-redux";
import { chooseCategory } from "../redux/actions/CategoryAction";
import { useNavigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";

function BannerImage() {
  const [banners, setBanners] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const GetBanners = async () => {
      if (banners?.length >= 1) {
        return;
      }
      try {
        const { data } = await axios.get(
          "https://secret-server.vercel.app/api/banners"
        );
        setBanners(data);
      } catch (error) {
        console.log(error);
      }
    };
    GetBanners();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplaySpeed: 1210,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="max-w-[1200px] h-[100%] w-full mx-auto mt-[63px] md:mt-[100px] px-[6px] md:px-0">
      {banners.length >= 1 && (
        <Slider
          autoplay={true}
          arrows={false}
          {...settings}
          useTransform={false}
        >
          {banners?.map((item, index) => (
            <img
              key={index}
              onClick={() => {
                dispatch(chooseCategory(item.category));
                navigate(`/categories/${item.category.name}`);
              }}
              src={item?.imageUrl}
              alt={`Slide`}
              className="h-[150px] md:h-[400px] w-full object-cover rounded-[2px]"
            />
          ))}
        </Slider>
      )}
    </div>
  );
}

export default BannerImage;
